html { -webkit-tap-highlight-color:rgba(0,0,0,0); -webkit-tap-highlight-color: transparent; }
body { background:#fdfdfd; -webkit-font-smoothing:antialiased; padding:0; margin:0; font-family: 'Montserrat', sans-serif; text-align:center; overflow-y:scroll; color:#000; font-size:18px; line-height:26px; }

a:link { color: black; }
a:visited { color: black; }
a:hover { color: black; }
a:active { color: black; }

label { display:block; font-size:14px; font-weight:500; box-sizing:border-box; }
input { height:32px; padding:6px 10px; display:block; font-family:Montserrat, sans-serif; font-size:18px; font-weight:500; margin-bottom:10px; -webkit-font-smoothing:antialiased; border-style:solid; border:2px solid #ddd; border-radius:4px; outline:none; width:100%; max-width:320px; margin-right:40px; }
input:focus{ border-radius:4px; border:2px solid #90caf9; }
input:-webkit-autofill,
input:-webkit-autofill:hover, 
input:-webkit-autofill:focus, 
input:-webkit-autofill:active  { -webkit-box-shadow: 0 0 0 30px white inset !important;  }

.nav-wrapper-old { position:relative; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; margin:auto; width:100%; max-width:1240px; box-sizing:border-box; }

.front-nav-wrapper { position:absolute; top:0; left:0; height:60px; width:100%; background:white; box-sizing:border-box; border-bottom:2px solid #ddd;  }
.front-nav { position:absolute; top:0; left:50%; margin:auto; transform:translateX(-50%); height:100%; width:100%; max-width:1240px; box-sizing:border-box; }

.nav-links { display:block; white-space:nowrap; }
@media only screen and ( min-width: 900px) {
.nav-links { display:block; }
}

.front-wrapper2 { position:relative; width:100%; height:100vh; background:#000; box-sizing:border-box; }
.front-wrapper { position:relative; top:0; left:0; width:100%; height:100vh; background:#000; box-sizing:border-box; }
.front-shadow { position:absolute; left:0; top:0; width:100%; height:100vh; background-image:linear-gradient( rgba(0,0,0,0.0), rgba(0,0,0,0.3) ) }

.front { position:absolute; left:30px; bottom:160px; text-align:left; font-size:44px; line-height:66px; color:white; font-weight:500; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; padding-right:20px;   }
.front-open-account { position:absolute; bottom:40px; left:30px; user-drag:none; --webkit-user-drag:none; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none;  }
.open-account-button { display:inline-block; cursor:pointer; padding:15px 30px; user-drag:none; margin-top:20px; margin-bottom:20px; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; font-size:18px; font-weight:500; background:#26a69a; color:white; border-radius:5px; }

.front-link-hover { border-bottom:2px solid rgba(255,255,255,0); }
.front-link-hover:hover { border-bottom:2px solid rgba(255,255,255,1); }

.button-front { border:2px solid white; width:100px; border-radius:4px; padding:8px 16px; font-weight:700; margin:10px; text-decoration:none; color:white; text-transform:uppercase; font-size:12px; letter-spacing:2px; }
.button-front-hover:hover { background:rgba(255,255,255,0.8); color:#444; }

h1 { font-weight:500; line-height:46px; font-size:40px; margin-top:0; }
h2 { font-weight:500; }
h3 { font-weight:500; }
h4 { font-weight:500; }
strong { font-weight:400; padding:5px 0 5px 0; color:#000; }
h2 { font-weight:400; font-size:22px; }
p { font-size:15px; }
.pointer { cursor:pointer; }

.info-wrapper { margin: 0; background:#e7e7e7; width:100%; padding:0 0 0 0; box-sizing:border-box; background:#e7e7e7; text-align:left; }
.info { display:inline-block; width:100%; box-sizing:border-box; text-align:left; padding:0 20px; vertical-align:top; }
.account-plan { display:inline-block; width:100%; box-sizing:border-box; text-align:left; padding:0 20px; vertical-align:top; }



.all-activities-tabs { height:40px; padding:6px 10px 0 10px; margin:2px; box-sizing:border-box; font-size:12px; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; font-weight:500; background-color:#efefef; cursor:pointer; border-bottom:5px solid #eee; }
.all-activities-tabs:hover { border-bottom:5px solid #00e5ff; }
.all-activities-tabs-selected { border-bottom:5px solid #00e5ff; }

.all-activities-pills { display:inline-block; margin:2.5px; padding:0 10px; border-radius:14px; border:1px solid black; font-size:12px; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; font-weight:500; cursor:pointer; }
.all-activities-pills:hover { background-color:#bbdefb; color:black; }
.all-activities-pills-selected { background-color:#1e88e5; color:white; }
@media only screen and ( min-width: 657px) {
	.all-activities-pills { width:95px; margin:5px 12.5px 5px 5px; padding:0 15px; }
	.all-activities-pills:hover { background-color:#bbdefb; color:black; }
	.all-activities-pills-selected { background-color:#1e88e5; color:white; }
}

.categories-list-block { display:inline-block; width:100%; height:200px; padding:0; margin:10px 30px 10px 10px; box-sizing:border-box; background-color:white; box-shadow:0 4px 8px rgba(0;0;0;0.2); color:black; }
@media only screen and ( min-width: 657px) {
	.categories-list-block { width:270px; height:200px; padding:0; margin:10px; box-sizing:border-box; background-color:white; box-shadow:0 4px 8px rgba(0;0;0;0.2); color:black; }
}

.activities-list-block { display:inline-block; width:100%; padding:0; margin:10px 30px 10px 10px; box-sizing:border-box; background-color:white; box-shadow:0 4px 8px rgba(0;0;0;0.2); color:black; }
@media only screen and ( min-width: 657px) {
	.activities-list-block { width:270px; padding:0; margin:10px; box-sizing:border-box; background-color:white; box-shadow:0 4px 8px rgba(0;0;0;0.2); color:black; }
}

.blogs-list-block { display:inline-block; width:100%; padding:0; margin:10px 30px 10px 10px; box-sizing:border-box; background-color:white; box-shadow:0 4px 8px rgba(0;0;0;0.2); color:black; }
@media only screen and ( min-width: 657px) {
	.blogs-list-block { width:270px; padding:0; margin:10px; box-sizing:border-box; background-color:white; box-shadow:0 4px 8px rgba(0;0;0;0.2); color:black; }
}

.card-hover { table-layout:fixed; border-collapse:collapse; width:100%; box-sizing:border-box; border-radius:5px; overflow:hidden; box-sizing:border-box; box-shadow:0 2px 5px rgba(0,0,0,0.2); }
.card-hover:hover { box-shadow:0 5px 6px rgba(0,0,0,0.15); }

.products-wrapper { margin: 0; width:100%; padding:0 0 60px 0; box-sizing:border-box; background:#e7e7e7; text-align:left; }
.products { margin: auto; width:100%; padding:40px 0 30px 0; max-width:1240px; box-sizing:border-box; }
.products-flex-box { display:block; }
.products-checkout { position:relative; width:100%; height:1572px; padding:20px; box-shadow:0 3px 6px rgba(0,0,0,0.2); background:white; margin:0; box-sizing:border-box; }
.products-checkout-component { position:relative; width:100%; padding:20px; box-shadow:0 3px 6px rgba(0,0,0,0.2); background:white; margin:0; box-sizing:border-box; }

.banner-parent { width:100%; height:300px; overflow:hidden; cursor:'pointer' }
.banner-child { width:100%; height:100%; transition: all 1.2s; }
.banner-img { object-fit:cover; width:100%; height:100%; }
.banner-parent:hover .banner-child, .banner-parent:focus .banner-child { transform: scale(1.025); }
.banner-child::before { content: ""; display: none; height: 100%; width: 100%; position: absolute; top: 0; left: 0; background-image:linear-gradient( rgba(0,0,0,0.05), rgba(0,0,0,0.25) );  }
.banner-parent:hover .banner-child:before,
.banner-parent:focus .banner-child:before { display: block; }

.gallery-background { position:fixed; z-index:2; left:0; top:0; width:100%; height:100%; background:#333; box-sizing:border-box; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; }
.gallery-wrapper { position:absolute; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; left:50%; top:50%; transform:translate( -50%, -50% ); width:100%; height:100%; padding:0 0 20px 0; max-width:1240px; box-sizing:border-box; }
.gallery-image { position:absolute; -webkit-backface-visibility:hidden; backface-visibility:hidden; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; left:0; top:50%; transform:translateY(-50%); width:100%; height:100%; object-fit:contain; box-sizing:border-box; }
.gallery-image-left { position:absolute; -webkit-backface-visibility:hidden; backface-visibility:hidden; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; left:-100%; top:50%; transform:translateY(-50%); width:100%; height:100%; object-fit:contain; box-sizing:border-box; }
.gallery-image-right { position:absolute; -webkit-backface-visibility:hidden; backface-visibility:hidden; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; left:100%; top:50%; transform:translateY(-50%); width:100%; height:100%; object-fit:contain; box-sizing:border-box; }

.gallery-close { position:absolute; right:0; top:0; cursor:pointer; width:50%; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; color:white; font-size:32px; }
.gallery-close > i { position:fixed; right:10px; top:10px; padding:20px; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; background-color:rgba(0,0,0,0.3); border-radius:50%; }
.gallery-close > i:hover { background-color:rgba(0,0,0,0.5); }

.gallery-arrow-left { position:absolute; left:0; top:50%; transform:translateY(-50%); cursor:pointer; width:110px; height:120px; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; color:white; font-size:32px; }
.gallery-arrow-left > i { position:fixed; left:10px; top:50%; transform:translateY(-50%); padding:20px; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; background-color:rgba(0,0,0,0.3); border-radius:50%; }
.gallery-arrow-left > i:hover { background-color:rgba(0,0,0,0.5); }

.gallery-arrow-right { position:absolute; right:0; top:50%; transform:translateY(-50%); cursor:pointer; width:110px; height:120px; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; color:white; font-size:32px; }
.gallery-arrow-right > i { position:fixed; right:10px; top:50%; transform:translateY(-50%); padding:20px; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; background-color:rgba(0,0,0,0.3); border-radius:50%; }
.gallery-arrow-right > i:hover { background-color:rgba(0,0,0,0.5); }

.footer-wrapper { margin:0; width:100%; text-align:left; -webkit-user-select:none; -moz-user-select:none; -ms-user-select:none; user-select:none; font-size:13px; padding:20px 0 10px 0; background-color:#263238; color:white; vertical-align:top; box-sizing:border-box;  }
.footer { margin:auto; width:100%; max-width:1240px; padding:30px; vertical-align:top; box-sizing:border-box;  }
.footer-flex-box { display:block; }
.footer-item-group { width:120px; min-width:120px; padding-right:20px; padding-bottom:20px; }

.mobile-only { display:block; }
.mobile-only-inline { display:inline-block; }
.desktop-only { display:none; }

@media only screen and (min-width: 768px) {
	.info { width:49%; }
	.account-plan { width:32%; }
	.products-flex-box { display:flex; }
	.products-checkout { width:380px; min-width:380px; margin:-90px 30px 140px 20px; }
	.products-checkout-component { width:380px; min-width:380px; margin:-90px 30px 140px 20px; }
	.footer-flex-box { display:flex; }
	.footer-item-group { width:270px; padding-right:20px; }
	.mobile-only { display:none; }
	.mobile-only-inline { display:none; }
	.desktop-only { display:block; }
}

a { text-decoration:none; color:black; }
a:hover {  }


.shadow { -webkit-filter: drop-shadow( 0px 2.5px 5px rgba(0,0,0,0.6) ); filter: drop-shadow( 0px 2.5px 5px rgba(0,0,0,0.6) ); }


